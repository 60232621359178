import "./Header.css";
import GasDisplay from "./GasDisplay.js";

const Header = (props) => {
  return (
    <header className="container">
      <div className="spacer">
        <span id="menubutton" onClick={props.toggleMenu}>
          ≡
        </span>
      </div>
      <h1>NFT-Dashboard</h1>

      <div className="spacer topright">
        <GasDisplay gas={props.gas} />
        <button onClick={props.refresh}>
          {props.waiting && <span>Loading...</span>}
          {!props.waiting && <span>refresh</span>}
        </button>
      </div>
    </header>
  );
};

export default Header;
