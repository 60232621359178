import "./Linkbox.css";

const Linkbox = (props) => {
  return (
    <div className="linkbox">
      {props.collection?.twitter_username && (
        <a
          href={`https://twitter.com/${props.collection?.twitter_username}`}
          target="_blank"
          rel="noreferrer"
        >
          <img
            className="logo"
            title="Twitter"
            src="twitter.png"
            alt="twitter Logo"
          />
        </a>
      )}
      <a
        href={`https://nft.wuestenigel.com/contract/&contractID=${props.collection?.primary_asset_contracts[0]?.address}`}
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="logo"
          title="Wuestenigel Sniper"
          src="https://nft.wuestenigel.com/apeimg.png"
          alt="wuesti logo"
        />
      </a>
      <a
        href={`https://opensea.io/collection/${props.slug}`}
        target="_blank"
        rel="noreferrer"
      >
        <img
          className="logo"
          title="OpenSea"
          src="https://storage.googleapis.com/opensea-static/Logomark/Logomark-Blue.png"
          alt="os logo"
        />
      </a>
    </div>
  );
};

export default Linkbox;
