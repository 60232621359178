import "./EthDisplay.css";

const EthDisplay = (props) => {
  const symbol = {
    USD: "$",
    EUR: "€",
  };

  const round = (price, decimals = 2) =>
    Math.round(10 ** decimals * price) / 10 ** decimals;

  return (
    <span>
      {props.amount && <span>{round(props.amount, 3)} ETH</span>}
      {props.amount && props.rates && props.rates[props.currency] && (
        <span className="fiat">
          {" "}
          ({round(
            props.amount * props.rates[props.currency]
          ).toLocaleString()}{" "}
          {symbol[props.currency]})
        </span>
      )}
    </span>
  );
};

export default EthDisplay;
