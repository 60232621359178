import { useState, useEffect } from "react";
import Tile from "./Tile.js";
import Header from "./Header.js";
import Footer from "./Footer.js";
import Menu from "./Menu.js";
import Landingpage from "./Landingpage.js";
import "./Dashboard.css";

const Dashboard = () => {
  // const typeOf = (s) => {
  //   if (s.indexOf("0x") === 0) {
  //     if (s.indexOf("/") > -1) {
  //       return "asset";
  //     }
  //     return "contract";
  //   }
  //   return "slug";
  // };

  const updateCollection = async (slug) => {
    return fetch(`https://api.opensea.io/api/v1/collection/${slug}`)
      .then((res) => res.json())
      .then((res) => {
        setCollections((prevCollection) => {
          return { ...prevCollection, [slug]: res.collection };
        });
      });
  };

  // const updateAsset = async (key) => {
  //   const [contract, tokenID] = key.split("/");
  //   return fetch(
  //     `https://api.opensea.io/api/v1/assets?asset_contract_address=${contract}&token_ids=${tokenID}`
  //   )
  //     .then((res) => res.json())
  //     .then((res) => {
  //       setAssets((prevAsset) => {
  //         return { ...prevAsset, [key]: res.assets[0] };
  //       });
  //     });
  // };

  // const updateEvents = async (slug) => {
  //   return fetch(`https://api.opensea.io/api/v1/events?collection_slug=${slug}`)
  //     .then((res) => res.json())
  //     .then((res) => {
  //       setEvents((prevEvents) => {
  //         return { ...prevEvents, [slug]: res };
  //       });
  //     });
  // };

  const updateRates = async () => {
    return fetch(
      "https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=EUR,USD"
    )
      .then((res) => res.json())
      .then((res) => setRates(res));
  };

  const updateGas = async () => {
    return fetch("https://ethgas.watch/api/gas")
      .then((res) => res.json())
      .then((res) =>
        setGas({
          slow: res.slow.gwei,
          normal: res.normal.gwei,
          fast: res.fast.gwei,
        })
      );
  };

  const handleRefresh = async (slug) => {
    setStatus((prevStatus) => {
      return { ...prevStatus, waiting: true };
    });
    const promises = [];
    const slugList = slug ? [slug] : watchlist;
    for (let slug of slugList) {
      promises.push(updateCollection(slug));
    }
    promises.push(updateGas());
    promises.push(updateRates());
    await Promise.all(promises);
    setStatus((prevStatus) => {
      return { ...prevStatus, waiting: false };
    });
  };

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  };

  const initialWatchlist = [
    "genesis-curry-flow",
    "rumble-kong-league-curry-flow",
    "chainfaces-arena",
    "gcf-gala-games",
    "therebelsnft",
    "zenacademy",
    "adidasoriginals",
    "cryptojankyz",
    "moodrollers-by-lucas-zanotto",
    "slimhoods",
    "coolmans-universe",
    "ao-art-ball",
    "wvrps-by-warpsound",
  ];

  const [status, setStatus] = useState({
    waiting: false,
    landingpage: !localStorage.getItem("apiUseAccepted"),
  });
  const [collections, setCollections] = useState({});
  const [watchlist, setWatchlist] = useState(
    localStorage.getItem("watchlist")?.split(",") || initialWatchlist
  );

  // const [assets, setAssets] = useState({});
  // const [events, setEvents] = useState({});
  const [menuActive, setMenuActive] = useState(false);
  const [rates, setRates] = useState({});
  const [gas, setGas] = useState({});

  useEffect(() => {
    handleRefresh();
  }, [watchlist]);

  if (status.landingpage)
    return (
      <Landingpage
        accept={() =>
          setStatus((prevStatus) => {
            localStorage.setItem("apiUseAccepted", "true");
            return { ...prevStatus, landingpage: false };
          })
        }
      />
    );

  return (
    <div className="dashboard container">
      <Header
        refresh={() => handleRefresh()}
        toggleMenu={() => toggleMenu()}
        waiting={status.waiting}
        gas={gas}
      />
      <div className="main container">
        {menuActive && (
          <Menu watchlist={watchlist} setWatchlist={setWatchlist} />
        )}
        <div className="tiles container">
          {watchlist.map((slug) => (
            <Tile
              key={slug}
              slug={slug}
              collection={collections[slug]}
              refresh={() => handleRefresh(slug)}
              rates={rates}
            />
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Dashboard;
