import "./Menu.css";

const Menu = (props) => {
  const addToWatchlist = () => {
    const input = window.prompt("opensea slug");
    if (!input) return;
    props.setWatchlist((prevWL) => {
      localStorage.setItem("watchlist", [...prevWL, input]);
      return [...prevWL, input];
    });
  };

  const deleteFromWatchlist = (slug) => {
    props.setWatchlist((prevWl) => {
      console.log(prevWl);
      console.log(prevWl.splice(prevWl.indexOf(slug), 1));
      console.log(prevWl);
      localStorage.setItem("watchlist", [...prevWl]);
      return [...prevWl];
    });
  };

  return (
    <div className="menu container">
      <div className="collection container">
        {props.watchlist.map((slug) => (
          <span key={slug} className="entry">
            <span className="delete" onClick={() => deleteFromWatchlist(slug)}>
              🗑
            </span>
            {slug}
          </span>
        ))}
      </div>
      <div className="addButton container" onClick={() => addToWatchlist()}>
        <button>+</button>
      </div>
    </div>
  );
};

export default Menu;
