import "./Landingpage.css";

const Landingpage = (props) => {
  return (
    <div className="landingpage dashboard container">
      <div className="infobox container">
        <div className="center container">
          This page will use APIs of:
          <div className="apiList">
            <ul>
              <li>opensea.io</li>
              <li>cryptocompare.com</li>
              <li>ethgas.watch</li>
            </ul>
          </div>
          Just like using those websites directly, your IP address and other
          usage data can be stored and used by them. <br></br>
          <button onClick={props.accept}>ok</button>
        </div>
      </div>
    </div>
  );
};

export default Landingpage;
