import "./GasDisplay.css";

const GasDisplay = (props) => {
  return props.gas ? (
    <span>
      ⛽: 🐢 {props.gas?.slow} 🚶‍♀️ {props.gas?.normal} ⚡ {props.gas?.fast}
    </span>
  ) : (
    <></>
  );
};

export default GasDisplay;
