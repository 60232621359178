import "./Footer.css";

const Footer = () => {
  return (
    <footer className="container">
      <span className="spacer"></span>
      <span>WAGMI</span>
      <span className="spacer">
        open source project. Contribute on github.com
      </span>
    </footer>
  );
};

export default Footer;
