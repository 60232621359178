import EthDisplay from "./EthDisplay.js";
import Linkbox from "./Linkbox.js";
import "./Tile.css";

const Tile = (props) => {
  const floorPrice = props.collection?.stats?.floor_price;
  const avgPrice = props.collection?.stats?.average_price;
  const multiToken =
    props.collection?.stats?.total_supply < props.collection?.stats?.num_owners;
  const oneDayAvgPrice = props.collection?.stats?.one_day_average_price;

  const osBugPossible = () =>
    floorPrice / oneDayAvgPrice > 1.8
      ? Math.round(floorPrice / oneDayAvgPrice)
      : false;

  const slugToName = (slug) => slug.replaceAll("-", " ");

  return (
    <div className="tile" onClick={props.refresh}>
      <h3>{props.collection?.name || slugToName(props.slug)}</h3>
      <img
        id="collection-image"
        alt=""
        src={
          props.collection?.featured_image_url || props.collection?.image_url
        }
      ></img>
      <div id="floor">
        Floor:{" "}
        <EthDisplay amount={floorPrice} rates={props.rates} currency="USD" />
        {multiToken && osBugPossible() && (
          <span> ({osBugPossible()}x Listing?) </span>
        )}
      </div>
      <div id="average">
        Ø: <EthDisplay amount={avgPrice} rates={props.rates} currency="USD" />
      </div>
      <span id="items">Items: {props.collection?.stats?.total_supply}</span>
      <div id="owners">Owners: {props.collection?.stats?.num_owners}</div>
      <div id="owners">Sales 24h: {props.collection?.stats?.one_day_sales}</div>
      {/* <div id="listed">Listed: ???</div> */}
      <Linkbox collection={props.collection} slug={props.slug} />
    </div>
  );
};

export default Tile;
